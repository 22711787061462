import { useEffect, useState } from 'react';
import { getCaptcha } from '../utils/authutil';
import {
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
  Button,
  Icon,
} from '@chakra-ui/react';
import { RiRefreshFill } from 'react-icons/ri';
import { logErr, logInfo } from '../utils/uiutil';

export const MyCaptcha = props => {
  const { onChange } = props;
  const [myhtml, setMyhtml] = useState('<p>This is text</p>');
  const [mykey, setMykey] = useState('<p>This is text</p>');
  const [entered, setEntered] = useState('');

  const changed = e => {
    setEntered(e.target.value);
    if (onChange) {
      onChange({
        key: mykey,
        value: e.target.value,
      });
    }
  };

  const refresh = async e => {
    const response = await getCaptcha();
    if (response?.data?.data?.auth?.captcha?.svg) {
      logInfo('Refresh captcha');
      const { svg, key } = response?.data?.data?.auth?.captcha;
      setMyhtml(svg);
      setMykey(key);
      if (onChange) {
        onChange({
          key,
          value: entered,
        });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getCaptcha();
      if (response?.data?.data?.auth?.captcha?.svg) {
        logInfo('Got captcha');
        const { svg, key } = response?.data?.data?.auth?.captcha;
        setMyhtml(svg);
        setMykey(key);
      }
      // this will log 'Hello Word' to the console
    };

    fetchData()
      // make sure to catch any error
      .catch(logErr);
  }, []);

  return (
    <div className="captchaControl" style={{ display: 'flex', width: '100%' }}>
      <div
        style={{ flex: 1 }}
        className="captchaImage"
        dangerouslySetInnerHTML={{ __html: myhtml }}
      ></div>
      <div style={{ flex: 1 }}>
        <Input
          style={{ flex: 1 }}
          type="text"
          placeholder=""
          className="captchaInput"
          maxLength="5"
          value={entered}
          onChange={changed}
        />
      </div>
      <div style={{ display: 'flex', textAlign: 'center' }}>
        <button
          style={{ flex: 1 }}
          className="captchaRefresh"
          onClick={refresh}
        >
          <Icon as={RiRefreshFill} boxSize={6} color="darkgreen" />
        </button>
      </div>
    </div>
  );
};
