import axios from 'axios';
import { TIMEOUTS, getServerUrl } from './appconsts';
import { isEmpty } from 'lodash';
import { apiCall } from './api';
import { logInfo } from './uiutil';

export const saveImages = async ({
  userId = '',
  propertyId = '',
  files,
  remove = [],
  timeout = TIMEOUTS.UPLOAD_IMAGES,
  headers = {},
}) => {
  // upload new images
  // if (isEmpty(files)) {
  //   return;
  // }

  const newFiles = files.filter(f => !f.id);

  if (!isEmpty(newFiles)) {
    const formData = new FormData();
    newFiles.forEach(file => {
      formData.append('file', file);
    });
    formData.append('type', 'IM');
    formData.append('propertyId', propertyId);
    formData.append('userId', userId);
    formData.append('simulate', 'false');

    const resp = await axios.post(`${getServerUrl()}/upload`, formData, {
      timeout: timeout,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...headers,
      },
    });

    logInfo(resp.data);
    if (!isEmpty(resp?.data?.data)) {
      let newIdx = 0;
      for (
        let idx = 0;
        idx < files.length && newIdx < resp.data.data.length;
        idx++
      ) {
        if (!files[idx].id) {
          files[idx].id = resp.data.data[newIdx++].data?.id;
        }
      }
    }
  }

  const imgOrder = files.map(f => f.id);
  logInfo('New order', imgOrder);

  if (!isEmpty(remove)) {
    await updateImages({ remove });
  }

  // reorder and remove saved images
  return {};
};

export const updateImages = async ({ remove }) => {
  if (isEmpty(remove)) {
    return;
  }

  const resp = await apiCall({
    query: `mutation UpdateImages($input: UpdateImagesInput!) {
        property {
            updateImages(input: $input) {
                status
            }
        }
    }`,
    variables: {
      input: {
        remove,
      },
    },
  });
  return resp;
};

export const uploadImages = async ({
  userId = '',
  propertyId = '',
  files,
  timeout = TIMEOUTS.UPLOAD_IMAGES,
  headers = {},
}) => {
  const formData = new FormData();

  files.forEach(file => {
    formData.append('file', file);
  });
  formData.append('type', 'IM');
  formData.append('propertyId', propertyId);
  formData.append('userId', userId);
  formData.append('simulate', 'false');

  const resp = await axios.post(`${getServerUrl()}/upload`, formData, {
    timeout: timeout,
    headers: {
      'Content-Type': 'multipart/form-data',
      ...headers,
    },
  });
  return resp;
};
