import React, { useEffect } from 'react';
import {
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
  Button,
  Select,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../components/ColorModeSwitcher';
import { Logo } from '../components/Logo';
import styles from '../styles/styles.css';
import { useState } from 'react';
import { saveUser } from '../utils/api';
import _ from 'lodash';
import { logErr, logInfo, redirect } from '../utils/uiutil';
import { MyCaptcha } from '../components/MyCaptcha';
import MyEditor from '../components/MyEditor';
import {
  getPropertyById,
  listProperty,
  updateProperty,
} from '../utils/propertyutil';
import { LOCAL_KEYS, getData } from '../utils/storageutil';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  useNavigate,
  // Link,
  Redirect,
  useParams,
  // useRouteMatch
} from 'react-router-dom';
import { URLS } from '../utils/appconsts';
import StateSelect from '../components/StateSelect';

function AddPropertyPage() {
  const navigate = useNavigate();
  const user = getData(LOCAL_KEYS.user);

  // let { url } = useRouteMatch();
  let { id: propertyId } = useParams() || {};
  const [data, setData] = useState({
    title: '',
    titleErr: '',

    addr1: '',
    addr1Err: '',

    addr2: '',
    addr2Err: '',

    city: '',
    cityErr: '',

    state: '',
    stateErr: '',

    zip: '',
    zipErr: '',

    rooms: 1,
    roomsErr: '',

    baths: '1',
    bathsErr: '',

    about: '',
    aboutErr: '',

    rent: 0,
    rentErr: '',

    rentMin: '',
    rentMinErr: '',

    rentMax: '',
    rentMaxErr: '',

    captchaKey: '',
    captchaValue: '',
    captchaErr: '',
  });

  const isInvalid = field => {
    return !_.isEmpty(data[`${field}Err`]);
  };

  const validateInputs = () => {
    const dataUpd = {};
    if (!data.title) {
      dataUpd.titleErr = 'Title is required';
    }
    if (!data.about) {
      dataUpd.aboutErr = 'Description is required';
    }
    if (!data.addr1) {
      dataUpd.addr1Err = 'Address line 1 is required';
    }
    if (!data.city) {
      dataUpd.cityErr = 'City is required';
    }
    if (!data.state) {
      dataUpd.stateErr = 'State is required';
    }
    if (!data.zip) {
      dataUpd.zipErr = 'Zipcode is required';
    }
    if (!data.rooms || data.rooms < 1) {
      dataUpd.roomsErr = 'Rooms is required';
    }
    if (data.rent < 1) {
      dataUpd.rentErr = 'Rent is required';
    }
    if (!data.captchaValue) {
      dataUpd.captchaErr = 'Captcha is required';
    }
    logInfo('Data', dataUpd);

    setData({ ...data, ...dataUpd });

    return _.isEmpty(dataUpd);
  };

  const clearErr = field => {
    setData({ ...data, [`${field}Err`]: '' });
  };

  const onChangeCaptcha = ({ key, value }) => {
    setData({ ...data, captchaKey: key, captchaValue: value });
  };

  const saveProperty = async () => {
    logInfo('Saving property', data);
    if (validateInputs()) {
      let result;
      if (propertyId) {
        result = await updateProperty({ ...data, id: propertyId });

        if (
          result?.data?.data?.property?.updateProperty?.status === 'SUCCESS'
        ) {
          navigate(`/propertyImage/${propertyId}`, {
            replace: true,
            state: { action: 'propertySaved' },
          });
        }
      } else {
        result = await listProperty({ ...data, userId: user.id });

        if (result?.data?.data?.property?.listProperty?.status === 'SUCCESS') {
          // navigate('/status', { replace: true, state: { action: 'propertyListed' } });
          const id = result.data.data.property.listProperty.property.id;
          navigate(`/propertyImage/${id}`, {
            replace: true,
            state: { action: 'propertySaved' },
          });
        } else {
          logErr('Error listing property');
        }
      }
    }
  };

  const doCancel = () => {
    navigate(-1);
  };

  const updateValue = name => event => {
    setData({ ...data, [name]: event.target.value, [`${name}Err`]: '' });
  };

  const onDescriptionChange = val => {
    setData({ ...data, about: val });
  };

  useEffect(() => {
    const populateData = async () => {
      if (propertyId) {
        const response = await getPropertyById({ id: propertyId });
        logInfo('response is ', response);
        if (response?.data?.data?.property?.property?.property) {
          const prop = response?.data?.data?.property?.property?.property;

          setData({
            ...data,
            title: prop.title || '',
            about: prop.about || '',
            rent: prop.rentMax || '0',
            addr1: prop.address?.addr1 || '',
            addr2: prop.address?.addr2 || '',
            city: prop.address?.city || '',
            state: prop.address?.state || '',
            zip: prop.address?.zip || '',
            rooms: prop.rooms || '0',
          });
        }
      }
    };

    if (!user?.id) {
      navigate(URLS.NEED_LOGIN);
      return;
    }

    populateData().catch(err => {
      logInfo('Error loading property', err);
    });
  }, []);

  return (
    <Box
      margin="8px auto"
      maxW="sm"
      borderWidth="2px"
      borderRadius="lg"
      overflow="hidden"
    >
      <Grid minH="100vh" p={2}>
        <VStack spacing={4}>
          <Heading as="h2" size="md" noOfLines={1}>
            Add Property
          </Heading>
          <FormControl isInvalid={isInvalid('title')}>
            <FormLabel>Title</FormLabel>
            <Input
              type="text"
              placeholder="title... (max 140 characters)"
              value={data.title}
              onChange={updateValue('title')}
              maxLength={140}
            />
            <FormErrorMessage>{data.titleErr}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={isInvalid('about')}>
            <FormLabel>Description</FormLabel>
            <MyEditor
              value={data.about}
              placeholder="describe your listing..."
              onChange={onDescriptionChange}
              maxLength={2048}
            />
            {/* <Input type="text" placeholder=''
              value={data.about}
              onChange={updateValue('about')}
            /> */}
            <FormErrorMessage>{data.aboutErr}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={isInvalid('addr1')}>
            <FormLabel>Address Line 1</FormLabel>
            <Input
              type="text"
              placeholder=""
              value={data.addr1}
              onChange={updateValue('addr1')}
            />
            <FormErrorMessage>{data.addr1Err}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={isInvalid('addr2')}>
            <FormLabel>Address Line 2</FormLabel>
            <Input
              type="text"
              placeholder=""
              value={data.addr2}
              onChange={updateValue('addr2')}
            />
            <FormErrorMessage>{data.addr2Err}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={isInvalid('city')}>
            <FormLabel>City</FormLabel>
            <Input
              type="text"
              placeholder=""
              value={data.city}
              onChange={updateValue('city')}
            />
            <FormErrorMessage>{data.cityErr}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={isInvalid('state')}>
            <FormLabel>State</FormLabel>
            <StateSelect onChange={updateValue('state')} />
            <FormErrorMessage>{data.stateErr}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={isInvalid('zip')}>
            <FormLabel>Zipcode</FormLabel>
            <Input
              type="text"
              placeholder=""
              value={data.zip}
              onChange={updateValue('zip')}
              maxLength={6}
            />
            <FormErrorMessage>{data.zipErr}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={isInvalid('rooms')}>
            <FormLabel>Number of rooms</FormLabel>
            <Select placeholder="Select option" onChange={updateValue('rooms')}>
              <option value="0">Studio</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </Select>
            {/* <Input
              type="number"
              placeholder=""
              value={data.rooms}
              onChange={updateValue('rooms')}
            /> */}
            <FormErrorMessage>{data.roomsErr}</FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={isInvalid('baths')}
            onChange={updateValue('baths')}
          >
            <FormLabel>Number of baths</FormLabel>
            <Select placeholder="Select option">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Select>
            <FormErrorMessage>{data.bathsErr}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={isInvalid('rent')}>
            <FormLabel>Rent (monthly)</FormLabel>
            <Input
              type="number"
              placeholder=""
              value={data.rent}
              onChange={updateValue('rent')}
            />
            <FormErrorMessage>{data.rentErr}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={isInvalid('captcha')}>
            <FormLabel>Captcha verification</FormLabel>
            <MyCaptcha onChange={onChangeCaptcha} />
            <FormErrorMessage>{data.captchaErr}</FormErrorMessage>
          </FormControl>
          <Stack spacing={4} direction="row" align="center">
            <Button colorScheme="blue" size="md" onClick={saveProperty}>
              Save
            </Button>
            <Button colorScheme="gray" size="md" onClick={doCancel}>
              Cancel
            </Button>
          </Stack>
        </VStack>
      </Grid>
    </Box>
  );
}

export default AddPropertyPage;
