import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <div
      style={{
        position: 'relative',
        textAlign: 'center',
        padding: '8px',
        borderTop: '1px #bbb solid',
        fontSize: '.6em',
      }}
    >
      <Link to="/links">&copy; 2023 - Rentzig LLC</Link>
      <span style={{ paddingLeft: '3em', textDecoration: 'underline' }}>
        <Link to="/agreements">Terms and Conditions</Link>
      </span>
    </div>
  );
};

export default Footer;
