import { LOCAL_KEYS, getData } from '../utils/storageutil';
import { getMyProperties, removePropertyById } from '../utils/propertyutil';
import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  useNavigate,
  Link,
  Redirect,
  useParams,
  useSearchParams,
  // useRouteMatch
} from 'react-router-dom';
import {
  Box,
  Button,
  Heading,
  Text,
  theme as baseTheme,
  useDisclosure,
} from '@chakra-ui/react';
import styles from './myProperties.module.css';
import { theme } from '@chakra-ui/react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import PropertyListView from '../components/PropertyListView';
import { logInfo } from '../utils/uiutil';
import { URLS } from '../utils/appconsts';

const Actions = ({ property, onDelete, onShowProperty }) => {
  const id = property.id;
  const editLink = `/addProperty/${id}`;
  const imageLink = `/propertyImage/${id}`;
  const messageThreadsLink = URLS.MESSAGETHREADS + '/' + property?.id;

  const deleted = () => {
    onDelete && onDelete({ id, property });
  };

  const show = () => {
    onShowProperty && onShowProperty({ id, property });
  };

  return (
    <div className={styles.actionsDiv}>
      <Link to={messageThreadsLink}>Messages</Link>
      <Link to={editLink}>Edit</Link> &nbsp;
      <Link to={imageLink}>Images</Link> &nbsp;
      <Link to="#" onClick={show}>
        Show
      </Link>{' '}
      &nbsp;
      <Link to="#" onClick={deleted}>
        Delete
      </Link>
    </div>
  );
};

const DeletePropertyModal = ({
  isOpen,
  onConfirmDelete,
  onOpen,
  onClose,
  property,
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Delete Property</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>
              Delete property "{property.title} - {property.address?.addr1}"?
            </p>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => onConfirmDelete({ property })}
            >
              Yes
            </Button>
            <Button variant="ghost" onClick={onClose}>
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const MyProperties = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [myProperties, setMyProperties] = useState([]);
  const [currentProperty, setCurrentproperty] = useState({});
  let [searchParams, setSearchParams] = useSearchParams();
  const DEFAULT_PAGE = {
    count: 0,
    page: Math.max(1, parseInt(searchParams.get('pg') || '1')),
    size: 10,
  };
  const [page, setPage] = useState(DEFAULT_PAGE);

  const user = getData(LOCAL_KEYS.user);
  logInfo('User is', user);

  // checkUserLoggedIn({ user, navigate });

  const onDelete = ({ id, property }) => {
    setCurrentproperty(property);
    onOpen();
  };

  const onConfirmDelete = async ({ property }) => {
    if (property?.id) {
      await removePropertyById({ id: property.id });
      onClose();
      await loadPropertyData();
    }
  };

  const onShowProperty = ({ id, property }) => {
    navigate(`/showProperty/${id}`, {
      state: { action: 'viewProperty' },
    });
  };

  const onPageClick = pageNumber => {
    const u = `/myProperties?pg=${pageNumber}`;
    navigate(u);
    navigate(0);
  };

  const loadPropertyData = async () => {
    logInfo('LOAD_PROPERTY_DATA');
    if (!user?.id) {
      return;
    }

    const response = await getMyProperties({
      userId: user.id,
      page: Math.max(0, page.page - 1),
    });
    logInfo('response is ', response);
    if (response?.data?.data?.property?.myProperties?.properties) {
      logInfo('Found properties');
      const props = response?.data?.data?.property?.myProperties?.properties;
      const page = response?.data?.data?.property?.myProperties?.page;
      const mappedProps = props.map(o => ({
        ...o,
        id: o.id,
        title: o.title,
        address: o.address,
      }));
      setMyProperties(mappedProps);
      setPage(page);
      logInfo('LOADED_PROPERTY_DATA');
    } else {
      logInfo('Found no properties');
    }
  };

  useEffect(() => {
    const populateData = async () => {
      await loadPropertyData();
    };

    if (!user?.id) {
      navigate(URLS.NEED_LOGIN);
      return;
    }
    populateData().catch(logInfo('Error loading my properties'));
  }, []);

  const renderActions = ({ property }) => {
    return (
      <Actions
        property={property}
        onDelete={onDelete}
        onShowProperty={onShowProperty}
      />
    );
  };

  return (
    <div className={styles.main}>
      <Box>
        <Heading as="h2" size="md" noOfLines={1} className={styles.title}>
          My Properties
        </Heading>
        <PropertyListView
          page={page}
          properties={myProperties}
          renderActions={renderActions}
          onPageClick={onPageClick}
        />
        {/* <PropertiesList
        properties={myProperties}
        onDelete={onDelete}
        onShowProperty={onShowProperty}
      /> */}
        <DeletePropertyModal
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          property={currentProperty}
          onConfirmDelete={onConfirmDelete}
        />
      </Box>
    </div>
  );
};

export default MyProperties;
