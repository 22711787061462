import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Spinner,
  Center,
  Icon,
  Button,
  Stack,
} from '@chakra-ui/react';
import {
  useSearchParams,
  Link as GotoLink,
  useLocation,
} from 'react-router-dom';
import { FaCheck, FaExclamationCircle } from 'react-icons/fa';

function StatusPage(props) {
  const { state } = useLocation();
  const { action } = state;
  // const [searchParams, setSearchParams] = useSearchParams();
  // const token = searchParams.get('token');

  const links = {
    generic: {
      message: 'The information has been updated.',
      icon: 'check',
    },
    passwordChanged: {
      message: 'Your password has been updated.',
      icon: 'check',
    },
    accountCreated: {
      message: 'Your account has been created. Welcome to rentzig!!!',
      icon: 'check',
    },
    emailConfirmed: {
      message: 'Your email has been confirmed.',
      icon: 'check',
    },
  };

  const actionData = links[action] || links['generic'];
  const [mystate, setMystate] = useState(actionData);

  return (
    <>
      <Center height="80vh">
        <Box display="flex" flexDirection="row">
            <>
              <Stack direction="column" spacing={4} textAlign="center">
                <Stack direction="row" spacing={4}>
                  <Icon as={FaCheck} boxSize={6} color="darkgreen"/>
                  <Text marginLeft="20px">{mystate.message}</Text>
                </Stack>
                <Link as={GotoLink} to="/">
                  <Button colorScheme="teal" variant="solid">
                    Back to Home
                  </Button>
                </Link>
              </Stack>
            </>
        </Box>
      </Center>
    </>
  );
}

export default StatusPage;
