import { Select } from '@chakra-ui/react';
import { UsaStates } from 'usa-states';

const StateSelect = ({ onChange }) => {
  const usStates = new UsaStates();
  const options = usStates.states.map(st => (
    <option value={st.abbreviation}>{st.name}</option>
  ));
  return (
    <Select placeholder="Select option" onChange={onChange}>
      {options}
    </Select>
  );
};

export default StateSelect;
