import { useState } from 'react';
import { findLocations } from '../utils/locationsutil';
import Autosuggest from 'react-autosuggest';
import styles from './LocationSearchInput.module.css';

const LocationSearchInput = ({ onChange, value }) => {
  // console.log(usCityOptions);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(value || '');

  const getSuggestions = async inputValue => {
    if (inputValue && inputValue.length < 3) {
      return [];
    }
    const resp = await findLocations({ prefix: inputValue });
    if (resp.data?.data?.ui?.locations?.locations) {
      const locs = resp.data?.data?.ui?.locations?.locations;
      const options = locs.map(l => ({
        label: l.city + ', ' + l.state,
        value: l.city + ':' + l.state,
      }));
      return options;
    }
    return [];
  };

  const onValueChange = (event, { newValue }) => {
    setSelected(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = async ({ value }) => {
    const suggestions = await getSuggestions(value);
    setOptions(suggestions);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setOptions([]);
  };

  // const { value, suggestions } = this.state;

  const getSuggestionValue = suggestion => suggestion.label;

  // Use your imagination to render suggestions.
  const renderSuggestion = suggestion => <div>{suggestion.label}</div>;

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: 'Type a city or zip code',
    selected,
    onChange: onValueChange,
    value: selected,
  };

  // Finally, render it!
  return (
    <div className="locationSearch">
      <Autosuggest
        suggestions={options}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    </div>
  );
};

export default LocationSearchInput;
