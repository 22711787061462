import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { searchProperties } from '../utils/searchProperties';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styles from './findProperties.module.css';
import MyPagination from '../components/MyPagination';
import { logErr, logInfo } from '../utils/uiutil';
import LocationSearchInput from '../components/LocationSearchInput';

const FindPropertiesPage = () => {
  // Search input - zip, distance 5, 10, 25 mi,
  // rooms, rent, features (type of house, pets, pool, parking)
  let [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  // let { id: propertyId } = useParams() || {};

  const [data, setData] = useState({
    // zip: searchParams.get('zip') || '',
    loc: searchParams.get('loc') || '',
    distance: searchParams.get('dist') || '',
    rent: searchParams.get('rent') || '',
  });

  const [properties, setProperties] = useState([]);
  const DEFAULT_PAGE = {
    count: 0,
    page: Math.max(1, parseInt(searchParams.get('pg') || '1')),
    size: 10,
  };
  const [page, setPage] = useState(DEFAULT_PAGE);

  const onChangeValue = field => e => {
    logInfo('Set', field, e.target.value);
    setData({ ...data, [field]: e.target.value });
  };

  const onLocationChange = loc => {
    setData({ ...data, loc });
  };

  const onShowProperty = id => {
    navigate(`/showProperty/${id}`, {
      state: { action: 'viewProperty' },
    });
  };

  const loadProperties = async () => {
    // if (data.zip) {
    logInfo('Search location', data);

    const resp = await searchProperties({
      location: data.loc,
      distance: data.distance,
      rent: data.rent,
      size: 12,
      page: Math.max(page.page - 1, 0),
    });

    logInfo('Properties', resp);

    if (
      resp &&
      !_.isEmpty(resp.data?.data?.property?.findProperties?.properties)
    ) {
      const findprops = resp.data.data.property.findProperties;
      const props = findprops.properties;
      setProperties(props);
      setPage({ ...findprops.page });
    } else {
      setProperties([]);
      setPage(DEFAULT_PAGE);
    }
    // } else {
    //   logInfo('No zip');
    // }
  };

  const onSearchClick = async () => {
    // await loadProperties();
    const pg = 1;
    const u = `/findProperties?loc=${data.loc}&dist=${data.distance}&rent=${data.rent}&pg=1`;
    navigate(u);
    navigate(0);
  };

  const onPageClick = pageNumber => {
    const u = `/findProperties?loc=${data.loc}&dist=${data.distance}&rent=${data.rent}&pg=${pageNumber}`;
    navigate(u);
    navigate(0);
  };

  const onClear = () => {
    setProperties([]);
  };

  useEffect(() => {
    loadProperties().catch(logErr);
  }, []);

  //   useEffect(() => {
  //     const load = async () => {
  //       await loadProperties();
  //     };
  //     load().catch(logInfo);
  //   }, []);

  return (
    <>
      <Box alignSelf="center" m="4px auto" w="90%" pt={4} pb={16}>
        <Stack>
          <Box>
            <Heading as="h1" fontSize="1rem" textAlign="center">
              Search Home and Apartment Listings
            </Heading>
          </Box>
          <Grid className={styles.searchGrid}>
            <GridItem pl="2">
              {/* <Input
                type="text"
                value={data.zip}
                onChange={onChangeValue('zip')}
                placeholder="zip code"
              /> */}
              <LocationSearchInput
                onChange={onLocationChange}
                value={data.loc}
              />
            </GridItem>
            <GridItem pl="2">
              <InputGroup size="md">
                <Input
                  type="number"
                  value={data.distance}
                  onChange={onChangeValue('distance')}
                  placeholder="distance"
                />
                <InputRightAddon children="miles" />
              </InputGroup>
            </GridItem>
            <GridItem pl="2">
              <InputGroup size="md">
                <Input
                  type="number"
                  value={data.rent}
                  onChange={onChangeValue('rent')}
                  placeholder="rent"
                />
                <InputRightAddon children="$" />
              </InputGroup>
            </GridItem>
            <GridItem pl="2" className={styles.searchGridButtons}>
              <div className={styles.searchGridButtonsInner}>
                <Button colorScheme="blue" size="md" onClick={onSearchClick}>
                  Search rental
                </Button>
              </div>
            </GridItem>
          </Grid>
          <Grid>
            <GridItem mt={3} mb={4} alignContent="center" textAlign="center">
              <Text fontWeight="normal" alignSelf="center" justifySelf="center">
                <Link href="/addProperty">
                  If you would like to list a rental property. Please click
                  here: <b> List rental property </b>
                </Link>
              </Text>
            </GridItem>
          </Grid>
          <div></div>
          <div>
            <MyPagination page={page} onPageClick={onPageClick} />
          </div>
          <Grid
            gridAutoColumns={'auto'}
            gridTemplateColumns={'repeat(auto-fit, minmax(min-content, 350px))'}
            gap={4}
            justifyItems={'center'}
            justifyContent={'space-evenly'}
          >
            {!_.isEmpty(properties) &&
              properties.map(prop => (
                <GridItem>
                  <PropertyBox
                    property={prop}
                    onShowProperty={onShowProperty}
                  />
                </GridItem>
              ))}
          </Grid>
          {/* <Pagination page={page} data={data} /> */}
        </Stack>
      </Box>
    </>
  );
};

const PropertyBox = props => {
  const property = props.property;
  const addr = property.address || {};
  const onShowProperty = () => {
    if (props.onShowProperty) {
      props.onShowProperty(property.id);
    }
  };

  let image = _.get(property, 'images[0].url');
  let imageOverlayText;
  if ((property.title || '').startsWith('Test ')) {
    imageOverlayText = 'TEST DATA';
  }

  if (!image) {
    imageOverlayText = 'NO IMAGE';
    const imgIdx = Math.floor(Math.random() * 0);
    const stockImages = [
      '/pexels-dalila-dalprat-2179214-resized.jpg',
      '/pexels-emre-can-acer-2189685-resized.jpg',
    ];
    image = stockImages[imgIdx];
  }

  return (
    <div className={styles.propertyBox} onClick={onShowProperty}>
      <Grid
        gridAutoColumns={'1fr'}
        // gridTemplateColumns={'200px 1fr'}
        gap="0"
      >
        <GridItem className={styles.imageContainerGridItem}>
          {/* <Image w="100%" h="100%" src={image} p={1} /> */}
          <div className={styles.imageContainer}>
            <img src={image} alt="Home" className={styles.image} />
            {imageOverlayText && (
              <div className={styles.imageCentered}>{imageOverlayText}</div>
            )}
          </div>
        </GridItem>
        <Grid pl={4} pt={2}>
          <GridItem className={styles.details}>
            <p>
              <span className={styles.propLabel}>Rooms: </span>
              <span className={styles.propValue}>{property.rooms || '0'}</span>
            </p>
          </GridItem>
          <GridItem className={styles.details}>
            <p>
              <span className={styles.propLabel}>Rent: </span>
              <span className={styles.propValue}>
                ${property.rentMax || '0'}
              </span>
            </p>
          </GridItem>
          <GridItem className={styles.details}>
            <span className={styles.propLabel}>Address: </span>
            <span className={styles.propValueDiv}>
              {addr.addr1}, {addr.addr2}, <br /> {addr.city}, {addr.zip}
            </span>
          </GridItem>
          <GridItem>
            <span className={styles.propTitle}>{property.title}</span>
          </GridItem>
        </Grid>
        {/* <Grid gridTemplateRows={'2em 1fr'}>
          <GridItem pl="4" fontWeight="bold" className={styles.titlcContainer}>
            <h2>{property.title}</h2>
          </GridItem>
          <GridItem pl="4" className={styles.details}>
            <p>
              <span>Rooms: {property.rooms || '0'}</span>
              <span style={{ marginLeft: '26px' }}>
                Rent: ${property.rentMax}
              </span>
            </p>
            <p>
              {addr.addr1}, {addr.addr2}, {addr.city}, {addr.zip}
            </p>
            <span dangerouslySetInnerHTML={{ __html: property.about }}></span>
          </GridItem>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default FindPropertiesPage;
