import { Box } from '@chakra-ui/react';
import styles from './health.module.css';

const HealthPage = () => {
  return (
    <Box>
      <div>
        <span className={styles.label}>Environment</span>
        <span className={styles.value}>{process.env.NODE_ENV}</span>
      </div>
    </Box>
  );
};

export default HealthPage;
