import PolicyDisclaimer from '../components/PolicyDisclaimer';
import PolicyPrivacy from '../components/PolicyPrivacy';
import PolicyTermsAndConditions from '../components/PolicyTermsAndConditions';
import styles from './agreements.module.css';

const AgreementsPage = () => {
  return (
    <>
      <div className={styles.termsContainer}>
        <div className={styles.terms}>
          <PolicyTermsAndConditions />
        </div>
        <div className={styles.disclaimer}>
          <PolicyDisclaimer />
        </div>
        <div className={styles.privacy}>
          <PolicyPrivacy />
        </div>
      </div>
    </>
  );
};

export default AgreementsPage;
