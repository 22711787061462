import axios from 'axios';
import { TIMEOUTS, getServerUrl } from './appconsts.js';
import { LOCAL_KEYS, saveData } from './storageutil.js';

export async function loginUser({ username = '', password = '' }) {
  const res = await apiCall({
    query: `mutation Login($input: LoginInput!) {
        auth {
          login(input: $input) {
            status
            user {
              id
              username
              email
              firstName
              lastName
            }
            token
            expires
          }
        }
      }`,
    variables: {
      input: {
        username,
        password,
      },
    },
  });

  if (res?.data?.data?.auth?.login?.status === 'SUCCESS') {
    const user = res?.data?.data?.auth?.login?.user;

    saveData(LOCAL_KEYS.user, user);
  }
  return res;
}

export async function saveUser(user) {
  return apiCall({
    query: `mutation SaveUser($input: SaveUserInput!) {
            user {
              saveUser(input: $input) {
                status
                user {
                  id
                }
              }
            }
          }`,
    variables: {
      input: {
        user: {
          username: user.username,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          password: user.password,
        },
      },
    },
    headers: {
      'x-captcha-key': user.captchaKey,
      'x-captcha-value': user.captchaValue,
    },
  });
}

export async function apiCall({
  query,
  variables,
  timeout = TIMEOUTS.DEFAULT,
  headers = {},
}) {
  const resp = await axios.post(
    `${getServerUrl()}/graphql`,
    { query, variables },
    {
      timeout: timeout,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    }
  );
  return resp;
}
