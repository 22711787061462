import React, { createRef, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { logInfo } from '../utils/uiutil';

const MyEditor = props => {
  const quill = useRef(null);

  const [value, setValue] = useState(props.value);
  // logInfo('MyEditor updated', props, value);

  const onValueChange = val => {
    setValue(val);
    if (props.onChange) {
      props.onChange(val);
    }
  };

  // logInfo('quill1', quill, value);
  useEffect(() => {
    if (quill?.current?.getEditor()) {
      // logInfo('SET_QUILL_VALUE', value);
      // quill.current.getEditor().setText(value || '');
      setValue(props.value);
    }
  }, [props.value]);

  return (
    <ReactQuill
      theme="snow"
      value={value || ''}
      onChange={onValueChange}
      ref={quill}
    />
  );
};

export default MyEditor;
