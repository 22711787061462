import { useEffect, useState } from 'react';
import { LOCAL_KEYS, getData } from '../utils/storageutil';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getPropertyById } from '../utils/propertyutil';
import { Box, Button, Heading, Text, Textarea } from '@chakra-ui/react';
import { getMessageThreads } from '../utils/messageutil';
import { isEmpty } from 'lodash';
import { logErr, logInfo, tsToStr } from '../utils/uiutil';
import MyPagination from '../components/MyPagination';
import styles from './messageThreads.module.css';

const MessageThreadsPage = props => {
  const navigate = useNavigate();
  const user = getData(LOCAL_KEYS.user);
  const { id: propertyId } = useParams() || {};
  const [searchParams, setSearchParams] = useSearchParams();
  // const fromUserId = searchParams.get('from') || user.id;

  const [property, setProperty] = useState({});
  const [messageThreads, setMessageThreads] = useState([]);

  const [page, setPage] = useState({
    page: parseInt(searchParams.get('pg') || '1'),
    size: 10,
    count: 0,
  });

  //   const [text, setText] = useState('');

  const loadProperty = async () => {
    if (propertyId) {
      const resp = await getPropertyById({ id: propertyId });
      logInfo(resp);
      if (resp?.data?.data?.property?.property?.property) {
        setProperty(resp.data.data.property.property.property);
      }
    }
  };

  const onShowMessages = ({ messageThread }) => {
    navigate(
      `/messaging/${messageThread.property.id}?user2Id=${messageThread.user2.id}&user1Id=${messageThread.user1.id}`
    );
    return;
  };

  const loadMessageThreads = async () => {
    const resp = await getMessageThreads({
      userId: user.id,
      propertyId,
      page: page.page,
      size: 10,
    });
    const mts = resp?.data?.data?.messages?.messageThreads?.messageThreads;
    if (mts) {
      if (!isEmpty(mts)) {
        mts.forEach(item => {
          item.updatedAtStr = tsToStr(item.updatedAt);
        });
      }
      setMessageThreads(mts);
    }
  };

  const onPageClick = pageNumber => {
    const u = `/messageThreads?pg=${pageNumber}`;
    navigate(u);
    navigate(0);
  };

  useEffect(() => {
    const populateData = async () => {
      await Promise.all([loadMessageThreads(), loadProperty()]);
    };
    populateData().catch(logErr);
  }, []);

  // box to send new message

  return (
    <Box>
      <Text className={styles.title}>Your Coversations</Text>
      {property?.id && (
        <>
          <div>
            <Text textAlign="center">
              {property.address?.addressSingleLine}
            </Text>
          </div>
        </>
      )}
      <Text textAlign="center">
        (Click on conversation to see more details)
      </Text>
      <div className={styles.threadsContainer}>
        <div>
          <MyPagination page={page} onPageClick={onPageClick} />
        </div>

        {messageThreads.map((mt, idx) => (
          <MessageThreadDisplay
            messageThread={mt}
            idx={idx}
            key={idx}
            onShowMessages={onShowMessages}
          />
        ))}
      </div>
    </Box>
  );
};

const MessageThreadDisplay = ({ messageThread, idx, onShowMessages }) => {
  return (
    <Box>
      <div
        key={idx}
        className={styles.threadBox}
        onClick={() => onShowMessages({ messageThread })}
      >
        <div>
          <p className={styles.threadInfo}>
            <span className={styles.infoLabel}>From:</span>
            {messageThread.user1.username}
          </p>
          <p className={styles.threadInfo}>
            <span className={styles.infoLabel}>To:</span>
            {messageThread.user2.username}
          </p>
          <p className={styles.threadInfo}>
            <span className={styles.infoLabel}>Updated:</span>
            {messageThread.updatedAtStr}
          </p>
          <p className={styles.threadInfo}>
            <span className={styles.infoLabel}>Address:</span>
            {messageThread.property?.address?.addressSingleLine}
          </p>
        </div>
      </div>
    </Box>
  );
};

export default MessageThreadsPage;
