import React from 'react';
import _ from 'lodash';
import {
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
  Button,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../components/ColorModeSwitcher';
import { Logo } from '../components/Logo';
import styles from '../styles/styles.css';
import { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { sendResetPasswordEmail } from '../utils/authutil';
import { logInfo } from '../utils/uiutil';

function RecoverPasswordPage() {
  const [data, setData] = useState({
    error: '',

    email: '',
    emailErr: '',
  });

  const navigate = useNavigate();

  const doRecoverPassword = async () => {
    clearErrors();
    if (!validateInputs()) {
      return;
    }

    const response = await sendResetPasswordEmail({ email: data.email });
    if (
      response?.data?.data?.auth?.sendPasswordResetEmail?.status === 'SUCCESS'
    ) {
      logInfo('Success');
      navigate('/', { replace: true });
    } else {
      setData({ ...data, error: 'failed to reset password.' });
      logInfo('Reset failed');
    }

    // const response = await loginUser(data);
    // logInfo('login', response);
    // if (response?.data?.data?.user?.login?.status === 'SUCCESS') {
    //   logInfo('Login success');
    //   navigate('/', { replace: true });
    // } else {
    //   setData({...data, error: 'Please check your username and password.'})
    //   logInfo('Login failed');
    // }
  };

  const validateInputs = () => {
    const dataUpd = {};
    if (!data.email) {
      dataUpd.emailErr = 'Email is required';
    }
    setData({ ...data, error: '', ...dataUpd });
    return _.isEmpty(dataUpd);
  };

  const isInvalid = field => {
    return !_.isEmpty(data[`${field}Err`]);
  };

  const updateValue = name => event => {
    setData({ ...data, [name]: event.target.value, [`${name}Err`]: '' });
  };

  const clearErrors = () => {
    setData({ ...data, emailErr: '', error: '' });
  };

  return (
    <Box
      margin="8px auto"
      maxW="sm"
      borderWidth="2px"
      borderRadius="lg"
      overflow="hidden"
    >
      <Grid p={2}>
        <VStack spacing={4}>
          <Heading as="h2" size="md" noOfLines={1}>
            Recover password
          </Heading>
          <Text display={data.error ? null : 'none'} color="red">
            {data.error}
          </Text>
          <FormControl isInvalid={isInvalid('email')}>
            <FormLabel>Email</FormLabel>
            <Input
              type="text"
              placeholder="you@email.com"
              value={data.email}
              onChange={updateValue('email')}
            />
            <FormErrorMessage>{data.emailErr}</FormErrorMessage>
          </FormControl>
          <Stack spacing={4} direction="row" align="center">
            <Button colorScheme="blue" size="md" onClick={doRecoverPassword}>
              Submit
            </Button>
            <Button colorScheme="gray" size="md">
              Reset
            </Button>
          </Stack>
        </VStack>
      </Grid>
    </Box>
  );
}

export default RecoverPasswordPage;
