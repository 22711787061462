import { apiCall } from './api';

export const findLocations = async ({ prefix } = {}) => {
  return apiCall({
    query: `query Locations($prefix: String) {
        ui {
            locations(prefix: $prefix) {
                locations {
                    city
                    state
                }
            }
        }
    }`,
    variables: {
      prefix,
    },
  });
};
