import { useEffect, useState } from 'react';
import { LOCAL_KEYS, getData } from '../utils/storageutil';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styles from './messaging.module.css';
import { getPropertyById } from '../utils/propertyutil';
import { Box, Button, Heading, Textarea } from '@chakra-ui/react';
import { getMessagesByPropertyId, postMessage } from '../utils/messageutil';
import { logErr, logInfo, tsToStr } from '../utils/uiutil';
import MyPagination from '../components/MyPagination';
import { URLS } from '../utils/appconsts';

const TEST_MESSAGES = [
  {
    direction: 'TO_LANDLORD',
    text: 'Hello there! Can you plase share the rent?',
  },
  {
    direction: 'TO_TENANT',
    text: 'Rent $900, utilities $80. No pets.',
  },
  {
    direction: 'TO_LANDLORD',
    text: 'I have 2 cats. Is that ok?',
  },
  {
    direction: 'TO_TENANT',
    text: 'Yes that is ok. How long do you plan to rent?',
  },
];
const MessagingPage = props => {
  const navigate = useNavigate();
  const user = getData(LOCAL_KEYS.user);
  const { id: propertyId } = useParams() || {};
  const [searchParams, setSearchParams] = useSearchParams();
  const user2Id = searchParams.get('user2Id');
  const user1Id = searchParams.get('user1Id') || user?.id;

  const [property, setProperty] = useState({});
  const [messages, setMessages] = useState(
    propertyId == '1' ? TEST_MESSAGES : []
  );

  const [page, setPage] = useState({
    page: parseInt(searchParams.get('pg') || '1'),
    size: 10,
    count: 0,
  });

  const [text, setText] = useState('');

  const loadProperty = async () => {
    const resp = await getPropertyById({ id: propertyId });
    logInfo(resp);
    if (resp?.data?.data?.property?.property?.property) {
      setProperty(resp.data.data.property.property.property);
    }
  };

  const loadMessages = async () => {
    const resp = await getMessagesByPropertyId({
      user1Id: user1Id || user?.id,
      propertyId,
      page: Math.max(page.page - 1, 0),
      size: 10,
    });
    const getMsg = resp?.data?.data?.messages?.messages?.messages;
    const pg = resp?.data?.data?.messages?.messages?.page;
    if (getMsg) {
      setMessages(getMsg.reverse());
      setPage(pg);
    }
  };

  const sendMessage = async () => {
    logInfo(
      `SEND MESSAGE prop ${property.userId} user ${user.id} from ${user1Id} to ${user2Id}`
    );

    // todo - add checks about tamperimg user1 and user2
    let resp = await postMessage({
      senderId: user.id,
      user1Id,
      user2Id,
      propertyId,
      text,
    });

    const result = resp.data?.data?.messages?.postMessage;
    if (result?.status === 'SUCCESS') {
      setText('');
      setMessages([...messages, result.message]);
    } else {
      logInfo('Message failed', resp);
    }
  };
  // display most recent 10 messages

  const doRefresh = async () => {
    await loadMessages();
  };

  const onPageClick = pageNumber => {
    const u = `/messaging/${propertyId}?pg=${pageNumber}&user1Id=${user1Id}&user2Id=${user2Id}`;
    navigate(u);
    navigate(0);
  };

  useEffect(() => {
    const populateData = async () => {
      await Promise.all([loadMessages(), loadProperty()]);
    };

    if (!user?.id) {
      navigate(URLS.NEED_LOGIN);
      return;
    }

    populateData().catch(logErr);
  }, []);

  // box to send new message

  return (
    <Box className={styles.mainBox}>
      <div className={styles.header}>
        <Heading as="h2" textAlign="center">
          Messages
        </Heading>
        <div className={styles.infoBox}>
          <div>
            (<span className={styles.infoLabel}>For the property at </span>
            <span className={styles.infoText}>
              {property?.address?.addressSingleLine}
            </span>
            )
          </div>
        </div>
      </div>
      <div className={styles.messagingContainer}>
        <div>
          <MyPagination page={page} onPageClick={onPageClick} />
        </div>
        {messages.map((msg, idx) => (
          <MessageDisplay message={msg} idx={idx} key={idx} />
        ))}
        <div>
          <Textarea
            value={text}
            onChange={e => setText(e.target.value)}
            placeholder="your text... (max 250 chars)"
            maxLength={250}
          />
        </div>
        <div style={{ display: 'flex', gap: '16px', justifyContent: 'center' }}>
          <Button colorScheme="blue" onClick={sendMessage}>
            Send
          </Button>
          <Button onClick={doRefresh}>Refresh</Button>
        </div>
      </div>
    </Box>
  );
};

const MessageDisplay = ({ message, idx }) => {
  let style =
    message.direction === 'TO_TENANT'
      ? styles.incomingMessage
      : styles.outgoingMessage;
  return (
    <div className={style} key={idx}>
      <div className={styles.timestamp}>{tsToStr(message.updatedAt)}</div>
      <p>{message.text}</p>
    </div>
  );
};

export default MessagingPage;
