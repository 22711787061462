import React from 'react';
import _ from 'lodash';
import {
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Heading,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  Stack,
  Button,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../components/ColorModeSwitcher';
import { Logo } from '../components/Logo';
import styles from '../styles/styles.css';
import { useState } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { resetPassword } from '../utils/userutil';
import { logErr } from '../utils/uiutil';

function ResetPasswordPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [confirmed, setConfirmed] = useState('');

  const [data, setData] = useState({
    error: '',
    
    password: '',
    passwordErr: '',

    password2: '',
    password2Err: '',

  });
  
  const navigate = useNavigate();

  const doResetPassword = async () => {
    clearErrors();
    if (!validateInputs()) {
      return;
    }
    const result = await resetPassword({ token, password: data.password });
    if (result?.data?.data?.auth?.resetPassword?.status == 'SUCCESS') {
      navigate('/status', { replace: true, state: { action: 'passwordChanged' } });
    } else {
      logErr('Error resetting password');
    }
  };

  const validateInputs = () => {
    const dataUpd = {};
    if (!data.password) {
      dataUpd.passwordErr = 'Password is required';
    }
    if (data.password !== data.password2) {
      dataUpd.password2Err = 'Passwords don\'t match';
    }
    setData({ ...data, error: '', ...dataUpd });
    return _.isEmpty(dataUpd);
  };

  const isInvalid = (field) => {
    return !_.isEmpty(data[`${field}Err`]);
  }

  const updateValue = (name) => (event) => {
    setData({ ...data, [name]: event.target.value, [`${name}Err`]: ''});
  };

  const clearErrors = () => {
    setData({...data, passwordErr: '', password2Err: '',error: '' });
  };

  return (
    <Box
      margin="8px auto"
      maxW="sm"
      borderWidth="2px"
      borderRadius="lg"
      overflow="hidden"
    >
      <Grid p={2}>
        <VStack spacing={4}>
          <Heading as='h2' size='md' noOfLines={1}>Set your new password</Heading>
          <Text display={data.error ? null : 'none'} color='red'>{data.error}</Text>
          <FormControl isInvalid={isInvalid('password')}>
            <FormLabel>Password</FormLabel>
            <Input type="password" placeholder='...'
              value={data.password}
              onChange={updateValue('password')}
            />
            <FormErrorMessage>{data.passwordErr}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={isInvalid('password2')}>
            <FormLabel>Password Repeat</FormLabel>
            <Input type="password" placeholder='...'
              value={data.password2}
              onChange={updateValue('password2')}
            />
            <FormErrorMessage>{data.password2Err}</FormErrorMessage>
          </FormControl>
          <Stack spacing={4} direction='row' align='center'>
              <Button colorScheme='blue' size='md' onClick={doResetPassword}>
                Submit
              </Button>
              <Button colorScheme='gray' size='md'>
                Reset
              </Button>
          </Stack>                    
        </VStack>
      </Grid>
    </Box>
  );
}

export default ResetPasswordPage;
