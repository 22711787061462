import React from 'react';
import _ from 'lodash';
import {
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
  Button,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../components/ColorModeSwitcher';
import { Logo } from '../components/Logo';
import styles from '../styles/styles.css';
import { useState } from 'react';
import { loginUser, saveUser } from '../utils/api';
import {
  Route,
  Routes,
  useNavigate,
  Link as RouteLink,
} from 'react-router-dom';
import { useUserStore } from '../stores/userStore';
import { MyCaptcha } from '../components/MyCaptcha';
import { logErr, logInfo } from '../utils/uiutil';
import { LOCAL_KEYS, getData, removeData } from '../utils/storageutil';
import { deleteUser } from '../utils/userutil';

function DeleteAccountPage() {
  const [data, setData] = useState({
    error: '',

    captchaKey: '',
    captchaValue: '',
    captchaValueErr: '',
  });

  const { user, removeUser } = useUserStore();

  const navigate = useNavigate();

  const onChangeCaptcha = ({ key, value }) => {
    logInfo('captcha', key, value);
    setData({ ...data, captchaKey: key, captchaValue: value });
  };

  const doDeleteAccount = async () => {
    logInfo('Deleting', user);
    if (validateInputs()) {
      const result = await deleteUser({
        id: user.id,
        captchaKey: data.captchaKey,
        captchaValue: data.captchaValue,
      });
      if (result?.data?.data?.user?.removeUser?.status === 'SUCCESS') {
        removeUser();
        await removeData(LOCAL_KEYS.user);

        navigate('/status', {
          replace: true,
          state: { action: 'accountCreated' },
        });
      } else {
        logErr('Error creating user');
      }
    }
  };

  const validateInputs = () => {
    const dataUpd = {};
    if (!data.captchaValue) {
      dataUpd.captchaValueErr = 'Captcha is required';
    }

    setData({ ...data, error: '', ...dataUpd });
    return _.isEmpty(dataUpd);
  };

  const isInvalid = field => {
    return !_.isEmpty(data[`${field}Err`]);
  };

  const updateValue = name => event => {
    setData({ ...data, [name]: event.target.value, [`${name}Err`]: '' });
  };

  const clearErrors = () => {
    setData({ ...data, usernameErr: '', passwordErr: '', error: '' });
  };

  return (
    <>
      <Box
        w="md"
        margin="8px auto"
        maxW="sm"
        borderWidth="2px"
        borderRadius="lg"
        overflow="hidden"
      >
        <Grid p={2}>
          <VStack spacing={4}>
            <Heading as="h2" size="md" noOfLines={1}>
              Delete Account
            </Heading>
            <Text display={data.error ? null : 'none'} color="red">
              {data.error}
            </Text>
            <Text>Please enter the captcha to confirm account deletion.</Text>
            <FormControl isInvalid={isInvalid('email')}>
              <FormLabel>Captcha verification</FormLabel>
              <MyCaptcha onChange={onChangeCaptcha} />
              <FormErrorMessage>{data.captchaValueErr}</FormErrorMessage>
            </FormControl>

            <Stack spacing={4} direction="row" align="center">
              <Button colorScheme="blue" size="md" onClick={doDeleteAccount}>
                Delete Account
              </Button>
              <Button colorScheme="gray" size="md">
                Reset
              </Button>
            </Stack>
          </VStack>
        </Grid>
      </Box>
    </>
  );
}

export default DeleteAccountPage;
