import React from 'react';
import _ from 'lodash';
import {
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
  Button,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../components/ColorModeSwitcher';
import { Logo } from '../components/Logo';
import styles from '../styles/styles.css';
import { useState } from 'react';
import { loginUser, saveUser } from '../utils/api';
import {
  Route,
  Routes,
  useNavigate,
  Link as RouteLink,
  useSearchParams,
} from 'react-router-dom';
import { useUserStore } from '../stores/userStore';
import { logInfo } from '../utils/uiutil';

function LoginPage() {
  const [data, setData] = useState({
    error: '',

    username: '',
    usernameErr: '',

    password: '',
    passwordErr: '',
  });

  const { setUser } = useUserStore();

  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const msg = searchParams.get('msg');

  const doLogin = async () => {
    clearErrors();
    if (!validateInputs()) {
      return;
    }

    const response = await loginUser(data);
    logInfo('login', response);
    if (response?.data?.data?.auth?.login?.status === 'SUCCESS') {
      const usr = response?.data?.data?.auth?.login?.user;
      setUser(usr);

      logInfo('Login success');
      navigate('/', { replace: true });
    } else {
      setData({ ...data, error: 'Please check your username and password.' });
      logInfo('Login failed');
    }
  };

  const validateInputs = () => {
    const dataUpd = {};
    if (!data.username) {
      dataUpd.usernameErr = 'Username is required';
    }
    if (!data.password) {
      dataUpd.passwordErr = 'Password is required';
    }

    setData({ ...data, error: '', ...dataUpd });
    return _.isEmpty(dataUpd);
  };

  const isInvalid = field => {
    return !_.isEmpty(data[`${field}Err`]);
  };

  const updateValue = name => event => {
    setData({ ...data, [name]: event.target.value, [`${name}Err`]: '' });
  };

  const clearErrors = () => {
    setData({ ...data, usernameErr: '', passwordErr: '', error: '' });
  };

  const doSignup = () => {
    navigate('/signup');
  };

  return (
    <>
      <Box
        w="95%"
        margin="8px auto"
        maxW="sm"
        borderWidth="2px"
        borderRadius="lg"
        overflow="hidden"
      >
        <Grid p={2}>
          <VStack spacing={4}>
            <Heading as="h2" size="md" noOfLines={1}>
              Login
            </Heading>
            <Text display={data.error ? null : 'none'} color="red">
              {data.error}
            </Text>
            {msg === 'need_login' && (
              <>
                <Text color="red">Please login or create an account.</Text>
              </>
            )}
            <FormControl isInvalid={isInvalid('username')}>
              <FormLabel>Email address</FormLabel>
              <Input
                type="test"
                placeholder="email@myemail.com"
                value={data.username}
                onChange={updateValue('username')}
              />
              <FormErrorMessage>{data.usernameErr}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={isInvalid('password')}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="***"
                value={data.password}
                onChange={updateValue('password')}
              />
              <FormErrorMessage>{data.passwordErr}</FormErrorMessage>
            </FormControl>
            <Stack spacing={4} direction="row" align="center">
              <Button colorScheme="blue" size="md" onClick={doLogin}>
                Login
              </Button>
              <Button colorScheme="gray" size="md" onClick={doSignup}>
                Sign Up
              </Button>
            </Stack>
            <Stack spacing={4} direction="row" align="center">
              <span style={{ paddingLeft: '3em', fontSize: '.75em' }}>
                By logging into Rentzig I agree to&nbsp;
                <Link
                  as={RouteLink}
                  to="/agreements"
                  style={{ textDecoration: 'underline' }}
                >
                  the Terms and Conditions.
                </Link>
              </span>
            </Stack>
          </VStack>
        </Grid>
      </Box>
      <Box marginTop="12px" textAlign="center">
        <Link as={RouteLink} to="/recoverPassword">
          <Text fontSize="sm" as="u">
            Forgot your password
          </Text>
        </Link>
      </Box>
    </>
  );
}

export default LoginPage;
