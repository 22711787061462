import * as router from 'react-router-dom';

export function logInfo(...args) {
  console.log(...args);
}

export function logErr(...args) {
  console.log(...args);
  // send error to server
}

export function redirect(target = '/') {
  return router.redirect(target);
}

export function tsToStr(ts) {
  if (!ts) {
    return '';
  }
  const tsint = parseInt(ts);
  const dt = new Date(tsint);
  return dt.toLocaleTimeString('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}
