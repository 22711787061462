import { apiCall } from './api';
import _ from 'lodash';

export const getMessageThreads = async ({ userId, propertyId = null }) => {
  return apiCall({
    query: `query GetMessages($input: MessageThreadsQueryInput!) {
      messages {
          messageThreads(input: $input) {
              messageThreads {
                  id
                  createdAt
                  updatedAt
                  user1Id
                  user2Id
                  propertyId
                  property {
                      id
                      address {
                          addressSingleLine
                      }
                  }
                  user1 {
                      id
                      username
                  }
                  user2 {
                      id
                      username
                  }
                  count
              }
              page {
                  hasNextPage
                  page
                  size
                  count
              }
          }
      }
  }`,
    variables: {
      input: {
        ..._.pickBy(
          {
            userId,
            propertyId,
          },
          _.identity
        ),
      },
    },
  });
};

export const postMessage = async ({
  senderId,
  user2Id = null,
  user1Id,
  propertyId,
  text,
}) => {
  return apiCall({
    query: `mutation PostMessage($input: PostMessageInput!) {
            messages {
                postMessage(input: $input) {
                    status
                    message {
                        id
                        direction
                        createdAt
                        updatedAt
                        text
                    }
                }
            }
        }`,
    variables: {
      input: {
        message: _.pickBy(
          {
            senderId,
            // user2Id,
            user1Id,
            propertyId,
            text,
          },
          _.identity
        ),
      },
    },
  });
};

export const getMessagesByPropertyId = async ({
  user1Id,
  propertyId,
  page = 0,
  size = 10,
} = {}) => {
  return apiCall({
    query: `query GetMessages($input: MessagesQueryInput!) {
        messages {
            messages(input: $input) {
                messages {
                    id
                    text
                    direction
                    user1Id
                    user2Id
                    propertyId
                    createdAt
                    updatedAt
                }
                page {
                    hasNextPage
                    page
                    size
                    count
                }
            }
        }
    }`,
    variables: {
      input: {
        user1Id,
        propertyId,
        page: { page, size },
      },
    },
  });
};
