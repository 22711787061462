export const LOCAL_KEYS = {
  user: 'user',
};
export const saveData = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeData = async key => {
  localStorage.removeItem(key);
};

export const getData = key => {
  let val = localStorage.getItem(key);
  if (val) {
    return JSON.parse(val);
  }
};
