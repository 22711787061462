import { Link, useNavigate } from 'react-router-dom';
import styles from '../styles/styles.css';
import { Icon } from '@chakra-ui/react';
import { GoHome } from 'react-icons/go';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { getLocalUser } from '../utils/authutil';
import { useUserStore } from '../stores/userStore';
import { isProd } from '../utils/appconsts';
import { logErr } from '../utils/uiutil';

export const Navbar = () => {
  // const [user, setUser] = useState({});
  const { user, setUser } = useUserStore();
  const matches = useMediaQuery('(min-width: 600px)');
  const navigate = useNavigate();

  const [menuState, setMenuState] = useState({
    displayed: matches ? true : false,
    icon: matches ? 'none' : 'inline-block',
    menu: matches ? 'flex' : 'none',
  });

  const toggleMenu = () => {
    if (menuState.displayed) {
      setMenuState({ displayed: false, icon: 'inline-block', menu: 'none' });
    } else {
      setMenuState({ displayed: true, icon: 'inline-block', menu: 'flex' });
    }
  };

  const onClickHome = () => {
    navigate('/');
  };

  const loadUser = async () => {
    const u = await getLocalUser();
    if (u) {
      setUser(u);
    } else {
      setUser(null);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await loadUser();
    };
    loadData().catch(logErr);
  }, []);

  return (
    <div className="navbar">
      <div className="navbar-left home" onClick={onClickHome}>
        <Icon as={GoHome} boxSize={4} />
        <h1>Rentzig</h1>
      </div>
      <div className="navbar-right" style={{ display: menuState.menu }}>
        <div className="navbar-link">
          <Link to="/">Home</Link>
        </div>
        <div className="navbar-link">
          <Link to="/addProperty">List</Link>
        </div>
        {user?.id ? (
          <>
            <div className="navbar-link">
              <Link to="/messageThreads">Messages</Link>
            </div>
            <div className="navbar-link">
              <Link to="/myProperties">Listings</Link>
            </div>
            <div className="navbar-link">
              <Link to="/myAccount">My Account</Link>
            </div>
          </>
        ) : (
          <>
            <div className="navbar-link">
              <Link to="/login">Login</Link>
            </div>
            <div className="navbar-link">
              <Link to="/signup">Signup</Link>
            </div>
          </>
        )}
        {!isProd() && (
          <div className="navbar-link">
            <Link to="/all">All Pages</Link>
          </div>
        )}
      </div>
      <div
        className="navbar-menu"
        style={{ display: menuState.icon }}
        onClick={toggleMenu}
      >
        <Icon as={GiHamburgerMenu} boxSize={8} />
      </div>
    </div>
  );
};

export default Navbar;
