import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import Navbar from './navbar';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
} from '@chakra-ui/react';
import Footer from './footer';
import ScrollToTop from '../components/ScrollToTop';

// NOTE: https://stackoverflow.com/questions/74168742/how-to-template-jsx-with-createbrowserrouter

const PageLayout = () => (
  <ChakraProvider theme={theme}>
    <ScrollToTop />
    <header>
      <Navbar />
    </header>
    <div style={{ minHeight: '92vh', paddingBottom: '16px' }}>
      <Outlet />
    </div>
    <Footer />
  </ChakraProvider>
);

export default PageLayout;
