// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".myAccount_mytitle__JKAwd {\r\n    text-align: center;\r\n    font-size: 1.25rem !important;\r\n}\r\n\r\n.myAccount_main__Irld3 {\r\n    margin: 0 auto;\r\n    width: 80%;\r\n    padding: 1rem;\r\n}\r\n\r\n.myAccount_actionsDiv__KqXrk {\r\n    margin-top: 8px;\r\n}\r\n\r\n.myAccount_actionsDiv__KqXrk {\r\n    margin: 0 auto;\r\n    text-align: center;\r\n}\r\n\r\n.myAccount_actionBtn__sjXbq {\r\n    margin-top: 1rem;\r\n    color-scheme: light;\r\n    width: max(60%, 12rem);\r\n}\r\n\r\n.myAccount_pageTitle__tImN1 {\r\n    font-size: 1.75rem;\r\n    font-weight: 800;\r\n    text-align: center;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/pages/myAccount.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,6BAA6B;AACjC;;AAEA;IACI,cAAc;IACd,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".mytitle {\r\n    text-align: center;\r\n    font-size: 1.25rem !important;\r\n}\r\n\r\n.main {\r\n    margin: 0 auto;\r\n    width: 80%;\r\n    padding: 1rem;\r\n}\r\n\r\n.actionsDiv {\r\n    margin-top: 8px;\r\n}\r\n\r\n.actionsDiv {\r\n    margin: 0 auto;\r\n    text-align: center;\r\n}\r\n\r\n.actionBtn {\r\n    margin-top: 1rem;\r\n    color-scheme: light;\r\n    width: max(60%, 12rem);\r\n}\r\n\r\n.pageTitle {\r\n    font-size: 1.75rem;\r\n    font-weight: 800;\r\n    text-align: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mytitle": "myAccount_mytitle__JKAwd",
	"main": "myAccount_main__Irld3",
	"actionsDiv": "myAccount_actionsDiv__KqXrk",
	"actionBtn": "myAccount_actionBtn__sjXbq",
	"pageTitle": "myAccount_pageTitle__tImN1"
};
export default ___CSS_LOADER_EXPORT___;
