import {
  Box,
  Button,
  Grid,
  Heading,
  Icon,
  Stack,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { saveImages, uploadImages } from '../utils/propertyimageutil';
import { getPropertyById } from '../utils/propertyutil';
import { TiDelete } from 'react-icons/ti';
import { useNavigate, useParams } from 'react-router-dom';
import { LOCAL_KEYS, getData } from '../utils/storageutil';
import { logInfo } from '../utils/uiutil';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const ImageUploadTwo = props => {
  // get property id, name, addr1
  const navigate = useNavigate();
  const user = getData(LOCAL_KEYS.user);
  let { id: propertyId } = useParams() || {};

  const userId = user.id;
  //   const propertyId = 'h9xcTJmRJbDKqN4BEH6VmK';

  const [files, setFiles] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);

  // Get existing images

  const doRemoveImage = async (file, fileIdx) => {
    let removed = [...removedImages];
    if (file.id) {
      removed.push(file.id);
      setRemovedImages(removed);
    }
    const remainingFiles = [...files];
    remainingFiles.splice(fileIdx, 1);
    setFiles(remainingFiles);
    logInfo('Files removed', removed);
  };

  const doUpload = async () => {
    // const newFiles = files.filter(f => !f.id);
    const resp = await saveImages({
      userId,
      propertyId,
      files,
      remove: removedImages,
    });
    logInfo('upload', resp);
    navigate(`/status`, {
      replace: true,
      state: { action: 'propertySaved' },
    });
  };

  const doClear = async () => {};

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    multiple: true,
    accept: { 'image/*': ['.png', '.jpg', '.jpeg'] },
    maxFiles: 5,
    onDrop: acceptedFiles => {
      setFiles([
        ...files,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const thumbs = files.map((file, fileIdx) => (
    <div style={thumb} key={file.preview}>
      <div style={thumbInner}>
        <img
          key={file.preview}
          src={file.preview}
          style={img}
          alt="Property"
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
      <div>
        <Icon
          as={TiDelete}
          boxSize={6}
          onClick={() => doRemoveImage(file, fileIdx)}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      const resp = await getPropertyById({ id: propertyId });
      if (resp.data?.data?.property?.property?.property?.images) {
        const oldImages =
          resp.data?.data?.property?.property?.property?.images || [];
        const imgs = oldImages.map(img => ({
          key: img.id,
          old: true,
          id: img.id,
          preview: img.thumbnail,
        }));
        setFiles(imgs);
      }
    };

    fetchImages().catch(err => logInfo(err));
  }, []);

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map(e => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    );
  });

  return (
    <Box
      margin="8px auto"
      maxW="sm"
      borderWidth="2px"
      borderRadius="lg"
      overflow="hidden"
    >
      <Grid minH="100vh" p={2}>
        <VStack spacing={4}>
          <Heading as="h2" size="md" noOfLines={1}>
            Upload Images of Apartment
          </Heading>

          <div className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside style={thumbsContainer}>{thumbs}</aside>
            {/* <aside>
                            <h4>Accepted files</h4>
                            <ul>{acceptedFileItems}</ul>
                            <h4>Rejected files</h4>
                            <ul>{fileRejectionItems}</ul>
                        </aside>        */}
          </div>

          <Stack spacing={4} direction="row" align="center">
            <Button colorScheme="blue" size="md" onClick={doUpload}>
              Save
            </Button>
            <Button colorScheme="gray" size="md" onClick={doClear}>
              Clear All
            </Button>
          </Stack>
        </VStack>
      </Grid>
    </Box>
  );
};

export default ImageUploadTwo;

// drop zone
// list of images

// upload and reorder images

// add - upload
// change - upload, set order
// remove - remove, set order
// move up - set order
// move down - set order
