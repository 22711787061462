import { apiCall } from './api';

export const removePropertyById = async ({ id }) => {
  return apiCall({
    query: `mutation RemoveProperty($input: RemovePropertyInput!) {
            property {
                removeProperty(input: $input) {
                    status
                }
            }
        }`,
    variables: {
      input: {
        id,
      },
    },
  });
};

export const getPropertyById = async ({ id }) => {
  return apiCall({
    query: `query GetProperty($input: GetPropertyInput!) {
            property {
                property(input: $input) {
                    property {
                        id
                        title
                        userId
                        # status
                        rooms
                        about
                        rentMax
                        address {
                            addressSingleLine
                            addr1
                            addr2
                            city
                            state
                            zip
                            country
                            coords {
                                latitude
                                longitude
                            }
                        }
                        images {
                            id
                            loc
                            thumbnail
                            url
                            title
                            type
                        }
                    }
                }
            }
        }`,
    variables: {
      input: {
        id,
      },
    },
  });
};

export const getMyProperties = async ({ userId, page = 0, size = 10 } = {}) => {
  return apiCall({
    query: `query MyProperties($input: MyPropertiesInput!) {
            property {
                myProperties(input: $input) {
                    page {
                        page
                        size
                        hasNextPage
                        count
                    }
                    properties {
                        id
                        title
                        userId
                        # status
                        about
                        address {
                          addressSingleLine
                            addr1
                            addr2
                            city
                            state
                            zip
                            country
                            coords {
                                latitude
                                longitude
                            }
                        }
                    }
                }
            }
        }`,
    variables: {
      input: {
        userId,
        page: {
          page,
          size,
        },
      },
    },
  });
};

export const listProperty = async ({
  userId,
  addr1,
  addr2,
  city,
  state,
  zip,
  country,
  title,
  about,
  rooms,
  baths = null,
  rent,
  captchaKey,
  captchaValue,
}) => {
  return apiCall({
    query: `mutation ListProperty($input: ListPropertyInput!) {
            property {
                listProperty(input: $input) {
                    status
                    property {
                        id
                    }
                }
            }
        }`,
    variables: {
      input: {
        userId,
        property: {
          title,
          about,
          rentMax: rent && parseInt(rent),
          rooms: rooms && parseInt(rooms),
          baths: baths && parseInt(baths),
          address: {
            addr1,
            addr2,
            city,
            state,
            zip,
            country,
          },
        },
      },
    },
    headers: {
      'x-captcha-key': captchaKey,
      'x-captcha-value': captchaValue,
    },
  });
};

export const updateProperty = async ({
  id,
  userId,
  addr1,
  addr2,
  city,
  state,
  zip,
  country,
  title,
  about,
  rooms,
  rent,
}) => {
  return apiCall({
    query: `mutation UpdateProperty($input: UpdatePropertyInput!) {
            property {
                updateProperty(input: $input) {
                    status
                }
            }
        }`,
    variables: {
      input: {
        property: {
          id,
          title,
          about,
          rentMax: parseInt(rent),
          rooms: parseInt(rooms),
          address: {
            addr1,
            addr2,
            city,
            state,
            zip,
            country,
          },
        },
      },
    },
  });
};
