import { apiCall } from './api';

export async function resetPassword({ token, password }) {
  return apiCall({
    query: `mutation ResetPassword($input: ResetPasswordInput!) {
        auth {
            resetPassword(input: $input) {
              status
            }
          }
        }`,
    variables: {
      input: {
        token: token,
        password: password,
      },
    },
  });
}

export async function confirmEmail(token) {
  return apiCall({
    query: `mutation VerifyEmail($input: VerifyEmailInput!) {
            auth {
                verifyEmail(input: $input) {
                  status
                  token {
                    token
                  }
                }
              }
            }`,
    variables: {
      input: {
        token: token,
      },
    },
  });
}

export async function deleteUser({ id, captchaKey, captchaValue }) {
  return apiCall({
    query: `mutation RemoveUser($id: ID!) {
            user {
              removeUser(id: $id) {
                status
              }
            }
          }`,
    variables: {
      id,
    },
    headers: {
      'x-captcha-key': captchaKey,
      'x-captcha-value': captchaValue,
    },
  });
}
