import { apiCall } from './api';
import { LOCAL_KEYS, getData, removeData } from './storageutil';
import { logInfo } from './uiutil';

export const getLocalUser = async () => {
  const user = getData(LOCAL_KEYS.user);
  if (!user) {
    return null;
  }
  if (user.lastChecked < Date.now() - 10 * 60 * 1000) {
    // verify user on server
    // TODO - load user
  }
  logInfo('USER IS', user);
  return user;
};

export async function sendResetPasswordEmail({ email }) {
  return apiCall({
    query: `mutation SendPasswordResetEmail($input: SendPasswordResetEmailInput!) {
        auth {
          sendPasswordResetEmail(input: $input) {
            status      
          }
        }
      }`,
    variables: {
      input: {
        email: email,
      },
    },
  });
}

export async function getCaptcha() {
  return apiCall({
    query: `query Captcha {
        auth {
          captcha {
            svg
            key      
          }
        }
      }`,
    variables: {},
  });
}

export async function logoutUser() {
  const response = await apiCall({
    query: `mutation Logout {
      auth {
        logout {
          status
        }
      }
    }`,
    variables: {},
  });

  // setItem(LOCAL_KEYS.user, null);
  await removeData(LOCAL_KEYS.user);

  if (response?.data?.data?.auth?.logout?.status !== 'SUCCESS') {
    logInfo('Logout failed');
    return response;
  }

  return response;
}
