// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".myProperties_title__f8UO2 {\r\n    text-align: center;\r\n    font-size: 1.75rem;\r\n}\r\n\r\n.myProperties_main__tOvoU {\r\n    margin: 0 auto;\r\n    width: 80%;\r\n    padding: 1rem;\r\n}\r\n\r\n.myProperties_actionsDiv__rHJXv {\r\n    margin-top: 8px;\r\n}\r\n\r\n.myProperties_actionsDiv__rHJXv a {\r\n    display: inline-block;\r\n    margin-right: 1rem;\r\n}", "",{"version":3,"sources":["webpack://./src/pages/myProperties.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;AACtB","sourcesContent":[".title {\r\n    text-align: center;\r\n    font-size: 1.75rem;\r\n}\r\n\r\n.main {\r\n    margin: 0 auto;\r\n    width: 80%;\r\n    padding: 1rem;\r\n}\r\n\r\n.actionsDiv {\r\n    margin-top: 8px;\r\n}\r\n\r\n.actionsDiv a {\r\n    display: inline-block;\r\n    margin-right: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "myProperties_title__f8UO2",
	"main": "myProperties_main__tOvoU",
	"actionsDiv": "myProperties_actionsDiv__rHJXv"
};
export default ___CSS_LOADER_EXPORT___;
