import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import App from '../App';
import PageLayout from './pagelayout';
import {
  Hello,
  ErrorPage,
  ChakraUI,
  SignupPage,
  LoginPage,
  ConfirmEmailPage,
  RecoverPasswordPage,
  ResetPasswordPage,
  StatusPage,
  AddPropertyPage,
  MyProperties,
  PropertyImageUpload,
  ImageUploadTwo,
  AllPages,
  FindPropertiesPage,
  ShowPropertyPage,
  MessagingPage,
  LogoutPage,
  DeleteAccountPage,
  AgreementsPage,
  MyAccountPage,
  HealthPage,
  SearchLinksPage,
} from '../pages';
import MessageThreadsPage from '../pages/messageThreads';

const Router = createBrowserRouter([
  {
    element: <PageLayout />,
    children: [
      {
        path: '/',
        element: <FindPropertiesPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/myAccount',
        element: <MyAccountPage />,
      },
      {
        path: '/showProperty/:id',
        element: <ShowPropertyPage />,
      },
      {
        path: '/findProperties',
        element: <FindPropertiesPage />,
      },
      {
        path: '/messaging/:id',
        element: <MessagingPage />,
      },
      {
        path: '/messageThreads',
        element: <MessageThreadsPage />,
      },
      {
        path: '/messageThreads/:id',
        element: <MessageThreadsPage />,
      },
      {
        path: '/all',
        element: <AllPages />,
      },
      {
        path: '/propertyImage/:id',
        element: <ImageUploadTwo />,
      },
      {
        path: '/myProperties',
        element: <MyProperties />,
      },
      {
        path: '/addProperty',
        element: <AddPropertyPage />,
      },
      {
        path: '/addProperty/:id',
        element: <AddPropertyPage />,
      },
      {
        path: '/signup',
        element: <SignupPage />,
      },
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        path: '/logout',
        element: <LogoutPage />,
      },
      {
        path: '/deleteAccount',
        element: <DeleteAccountPage />,
      },
      {
        path: '/recoverPassword',
        element: <RecoverPasswordPage />,
      },
      {
        path: '/resetPassword',
        element: <ResetPasswordPage />,
      },
      {
        path: '/chakraui',
        element: <ChakraUI />,
      },
      {
        path: '/confirmEmail',
        element: <ConfirmEmailPage />,
      },
      {
        path: '/status',
        element: <StatusPage />,
      },
      {
        path: '/agreements',
        element: <AgreementsPage />,
      },
      {
        path: '/health',
        element: <HealthPage />,
      },
      {
        path: '/links',
        element: <SearchLinksPage />,
      },
    ],
  },
]);

export default Router;
