import { Box, Button, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { LOCAL_KEYS, getData } from '../utils/storageutil';
import { useEffect } from 'react';
import { URLS } from '../utils/appconsts';
import styles from './myAccount.module.css';
import { logInfo } from '../utils/uiutil';

const MyAccountPage = () => {
  const navigate = useNavigate();
  const user = getData(LOCAL_KEYS.user);
  logInfo('User is', user);

  useEffect(() => {
    if (!user?.id) {
      navigate(URLS.NEED_LOGIN);
      return;
    }
  });

  const doEdit = () => {
    navigate(URLS.SIGNUP + `?userId=${user.id}`);
  };
  const doResetPassword = () => {
    navigate(URLS.RECOVERPASSWORD);
  };
  const doDeleteAccount = () => {
    navigate(URLS.DELETEACCOUNT);
  };
  const doLogout = () => {
    navigate(URLS.LOGOUT);
  };
  const doMyProperties = () => {
    navigate(URLS.MYPROPERTIES);
  };
  const doMessages = () => {
    navigate(URLS.MESSAGES);
  };
  return (
    <>
      <Box className={styles.main}>
        <div className={styles.title}>
          <Heading className={styles.mytitle}>My Account</Heading>
        </div>
        <Box className={styles.actionsDiv}>
          <div>
            <Button className={styles.actionBtn} onClick={doLogout}>
              Logout
            </Button>
          </div>
          <div>
            <Button className={styles.actionBtn} onClick={doResetPassword}>
              Reset password
            </Button>
          </div>
          <div>
            <Button className={styles.actionBtn} onClick={doMyProperties}>
              My listings
            </Button>
          </div>
          <div>
            <Button className={styles.actionBtn} onClick={doMessages}>
              My messages
            </Button>
          </div>
          <div>
            <Button className={styles.actionBtn} onClick={doEdit}>
              Edit your profile
            </Button>
          </div>
          <div>
            <Button className={styles.actionBtn} onClick={doDeleteAccount}>
              Delete Account
            </Button>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default MyAccountPage;

// My details
// Logout
// Delete Account
// View my Properties
// View my messages
// ====
// Payments
// Preferences??
// Stats
