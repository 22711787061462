import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import PolicyTermsAndConditions from './PolicyTermsAndConditions';
import PolicyDisclaimer from './PolicyDisclaimer';
import PolicyPrivacy from './PolicyPrivacy';
const styles = {};

function AgreementsModal(props) {
  const { open, closed } = props;
  const { isOpen, onOpen, onClose } = useDisclosure({ isOpen: open === true });
  const [scrollBehavior, setScrollBehavior] = useState('inside');

  const doClose = () => {
    if (closed) {
      closed();
    }
  };

  const btnRef = useRef(null);
  return (
    <>
      <Modal
        onClose={doClose}
        finalFocusRef={btnRef}
        isOpen={isOpen}
        scrollBehavior={scrollBehavior}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Terms and Conditions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className={styles.termsContainer}>
              <div className={styles.terms}>
                <PolicyTermsAndConditions />
              </div>
              <div className={styles.disclaimer}>
                <PolicyDisclaimer />
              </div>
              <div className={styles.privacy}>
                <PolicyPrivacy />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={doClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AgreementsModal;
