const baseStyles = {
  pageLink: {
    fontSize: '1.25rem',
    paddingRight: '.4rem',
    paddingLeft: '.4rem',
    textDecoration: 'underline',
  },
  pageLinkSelected: {
    fontSize: '1.25rem',
    paddingRight: '.4rem',
    paddingLeft: '.4rem',
    color: '#fff',
    borderRadius: '20%',
    backgroundColor: '#003',
  },
  pageInfo: {
    paddingLeft: '.4rem',
    fontSize: '.75rem',
  },
};

const MyPagination = ({ page, onPageClick, customStyles = null }) => {
  onPageClick = onPageClick || (() => {});
  let currentPage = 1;
  let pageCount = 1;
  if (page && page.count && page.size) {
    pageCount = Math.ceil(page.count / page.size) || 1;
    currentPage = page.page + 1;
  }
  let minPage = Math.max(1, currentPage - 5);
  let maxPage = Math.min(pageCount, minPage + 5);

  const styles = { ...baseStyles, ...customStyles };

  let pageLinks = [];
  for (let idx = minPage; idx <= maxPage; idx++) {
    if (idx === currentPage) {
      pageLinks.push(<span style={styles.pageLinkSelected}>{idx}</span>);
    } else {
      pageLinks.push(
        <a style={styles.pageLink} href="#" onClick={() => onPageClick(idx)}>
          {idx}
        </a>
      );
    }
  }

  if (pageLinks.length === 1) {
    pageLinks = [];
  }

  return (
    <>
      <span>{pageLinks}</span>
      <span style={styles.pageInfo}>
        (Page {currentPage} of {pageCount})
      </span>
    </>
  );
};

export default MyPagination;
