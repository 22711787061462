import { useNavigate } from 'react-router-dom';
import MyPagination from './MyPagination';
import styles from './PropertyListView.module.css';
import { isEmpty } from 'lodash';
import { Box, Button } from '@chakra-ui/react';

const PropertyListView = ({ page, properties, renderActions, onPageClick }) => {
  if (isEmpty(properties)) {
    return <NoProperties />;
  }
  return (
    <PropertiesList
      page={page}
      properties={properties}
      renderActions={renderActions}
      onPageClick={onPageClick}
    />
  );
};

const NoProperties = () => {
  const navigate = useNavigate();
  const doListRental = () => {
    navigate('/addProperty');
  };

  return (
    <Box textAlign="center" pt="3rem">
      No properties found. Click here to list a property.
      <div style={{ paddingTop: '2rem' }}>
        <Button colorScheme="blue" w="12rem" onClick={doListRental}>
          List a Rental
        </Button>
      </div>
    </Box>
  );
};

const PropertiesList = ({ page, properties, renderActions, onPageClick }) => {
  return (
    <>
      <div className={styles.propertiesList}>
        <MyPagination page={page} onPageClick={onPageClick} />
        {properties.map(prop => (
          <PropertyItem property={prop} renderActions={renderActions} />
        ))}
      </div>
    </>
  );
};

const PropertyItem = ({ property, renderActions }) => {
  return (
    <div className={styles.propertyItem}>
      <div>{property.title}</div>
      <div>{property.address?.addressSingleLine}</div>
      {renderActions && <div>{renderActions({ property })}</div>}
    </div>
  );
};

export default PropertyListView;
