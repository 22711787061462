// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".agreements_termsContainer__L-MVq {\r\n    width: 70%;\r\n    margin: 0 auto;\r\n    padding: 1em 1em;\r\n    border: 1px gray solid;\r\n    line-height: 1.4em;\r\n    box-shadow: 10px 10px 55px -11px rgba(0,0,0,0.75);\r\n}\r\n\r\n.agreements_termsContainer__L-MVq h2 {\r\n    text-align: center;\r\n}\r\n\r\n.agreements_terms__tmfDv {\r\n    padding-top: 2em;\r\n    border-bottom: 1px solid gray;\r\n}\r\n\r\n.agreements_disclaimer__hH0Ef {\r\n    padding-top: 2em;\r\n    border-bottom: 1px solid gray;\r\n}\r\n\r\n.agreements_privacy__UQoGQ {\r\n    padding-top: 2em;\r\n}\r\n\r\n", "",{"version":3,"sources":["webpack://./src/pages/agreements.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;IAKlB,iDAAiD;AACrD;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;IAChB,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".termsContainer {\r\n    width: 70%;\r\n    margin: 0 auto;\r\n    padding: 1em 1em;\r\n    border: 1px gray solid;\r\n    line-height: 1.4em;\r\n\r\n\r\n    -webkit-box-shadow: 10px 10px 55px -11px rgba(0,0,0,0.75);\r\n    -moz-box-shadow: 10px 10px 55px -11px rgba(0,0,0,0.75);\r\n    box-shadow: 10px 10px 55px -11px rgba(0,0,0,0.75);\r\n}\r\n\r\n.termsContainer h2 {\r\n    text-align: center;\r\n}\r\n\r\n.terms {\r\n    padding-top: 2em;\r\n    border-bottom: 1px solid gray;\r\n}\r\n\r\n.disclaimer {\r\n    padding-top: 2em;\r\n    border-bottom: 1px solid gray;\r\n}\r\n\r\n.privacy {\r\n    padding-top: 2em;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"termsContainer": "agreements_termsContainer__L-MVq",
	"terms": "agreements_terms__tmfDv",
	"disclaimer": "agreements_disclaimer__hH0Ef",
	"privacy": "agreements_privacy__UQoGQ"
};
export default ___CSS_LOADER_EXPORT___;
