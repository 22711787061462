import { apiCall } from './api';

export const searchProperties = async ({
  location,
  zip,
  distance = '40',
  rent = '50000',
  page = 0,
  size = 10,
}) => {
  distance = distance || '40';
  rent = rent || '50000';

  return apiCall({
    query: `query FindPropertiesByZip($input: FindPropertiesInput!) {
        property {
            findProperties(input: $input) {
                page {
                    page
                    size
                    hasNextPage
                    count
                }
                properties {
                    id
                    title
                    about
                    rooms
                    rentMax
                    address {
                        addr1
                        addr2
                        city
                        state
                        zip
                    }
                    images {
                        thumbnail
                        url
                    }
                }
            }
        }
    }`,
    variables: {
      input: {
        location,
        zip,
        radius: parseInt(distance) * 1600, // metres
        rentMax: parseInt(rent),
        page: {
          size,
          page,
        },
      },
    },
  });
};
