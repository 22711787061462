export const TIMEOUTS = {
  DEFAULT: 15000,
  SAVE_USER: 15000,
  UPLOAD_IMAGES: 20000,
};

export const URLS = {
  NEED_LOGIN: '/login?msg=need_login',
  LOGOUT: '/logout',
  DELETEACCOUNT: '/deleteAccount',
  MYPROPERTIES: '/myProperties',
  MESSAGES: '/messageThreads',
  MESSAGETHREADS: '/messageThreads',
  SIGNUP: '/signup',
  RESETPASSWORD: '/resetPassword',
  RECOVERPASSWORD: '/recoverPassword',
};

export const isProd = () => {
  console.log('ENV', process.env.NODE_ENV);
  return process.env.NODE_ENV === 'production';
};

export const getServerUrl = () => {
  if (isProd()) {
    return 'https://api.rentzig.com';
  } else {
    return 'http://192.168.1.242:4000';
  }
};

// export const SERVER_URL = isProd()
//   ? 'https://api.rentzig.com'
//   : 'http://192.168.1.242:4000';
