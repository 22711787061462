// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PropertyListView_propertyItem__pCF\\+8 {\r\n    margin-top: 1rem;\r\n    border: 1px solid grey;\r\n    border-radius: 4px;\r\n    padding: 12px;\r\n    box-shadow: 3px 2px 3px 0px rgba(0,0,0,0.75);\r\n}\r\n\r\n.PropertyListView_propertyItem__pCF\\+8 a {\r\n    text-decoration: underline;\r\n}", "",{"version":3,"sources":["webpack://./src/components/PropertyListView.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,4CAA4C;AAChD;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".propertyItem {\r\n    margin-top: 1rem;\r\n    border: 1px solid grey;\r\n    border-radius: 4px;\r\n    padding: 12px;\r\n    box-shadow: 3px 2px 3px 0px rgba(0,0,0,0.75);\r\n}\r\n\r\n.propertyItem a {\r\n    text-decoration: underline;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"propertyItem": "PropertyListView_propertyItem__pCF+8"
};
export default ___CSS_LOADER_EXPORT___;
