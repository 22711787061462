import { useNavigate, useParams } from 'react-router-dom';
import { getPropertyById } from '../utils/propertyutil';
import { useEffect, useState } from 'react';
import styles from './showProperty.module.css';
// import { Carousel } from 'react-responsive-carousel';
import { isEmpty } from 'lodash';
// Import css files
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button } from '@chakra-ui/react';
import { LOCAL_KEYS, getData } from '../utils/storageutil';
import { URLS } from '../utils/appconsts';
import { logErr, logInfo } from '../utils/uiutil';

const ImageDisplay = ({ image }) => {
  return (
    <div className={styles.imageContainer}>
      <img src={image.url} />
    </div>
  );
};

const PropertyCarousel = props => {
  const { property } = props;
  const onChange = () => {};
  const onClickItem = () => {};
  const onClickThumb = () => {};

  if (isEmpty(property.images)) {
    return (
      <>
        <div>No Images</div>
      </>
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    adaptiveHeight: true,
  };
  return (
    <div className={styles.imageCarouselContainer}>
      <Slider {...settings}>
        {property.images.map(image => (
          <ImageDisplay image={image} />
        ))}
      </Slider>
    </div>
  );
};

const ShowPropertyPage = props => {
  const navigate = useNavigate();
  const user = getData(LOCAL_KEYS.user);
  // let { url } = useRouteMatch();
  let { id: propertyId } = useParams() || {};
  const [property, setProperty] = useState({});

  const loadProperty = async () => {
    const resp = await getPropertyById({ id: propertyId });
    logInfo(resp);
    const prop = resp?.data?.data?.property?.property?.property;
    if (prop) {
      if (user && user.id === prop.userId) {
        prop.isOwner = true;
      }
      setProperty(prop);
    }
  };

  const onClickContact = () => {
    navigate(`/messaging/${propertyId}`);
  };

  const onClickViewThreads = () => {
    navigate(URLS.MESSAGETHREADS + `/${propertyId}`);
  };

  useEffect(() => {
    const populateData = async () => {
      await loadProperty();
    };
    populateData().catch(logErr);
  }, []);

  return (
    <div className={styles.main}>
      <h1 className={styles.title}>{property.title}</h1>
      {!isEmpty(property.images) && (
        <div className={styles.imageArea}>
          <PropertyCarousel property={property} />
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.mainBtn}>
          {property.isOwner ? (
            <>
              <Button colorScheme="blue" onClick={onClickViewThreads}>
                View messages
              </Button>
            </>
          ) : (
            <>
              <Button colorScheme="blue" onClick={onClickContact}>
                Contact Listing Owner
              </Button>
            </>
          )}
        </div>
        <div className={styles.info}>
          <p className={styles.rooms}>Rooms: {property.rooms}</p>
          <p className={styles.rent}>Rent: ${property.rentMax}</p>
        </div>
        <div className={styles.address}>
          <p>
            <b>Address</b>
          </p>
          <p>{property.address?.addr1}</p>
          {property.address?.addr2 && <p>{property.address?.addr2}</p>}
          <p>
            {property.address?.city}, {property.address?.state},{' '}
            {property.address?.zip}
          </p>
        </div>
        <div className={styles.description}>
          <b>Information about the rental</b>
          <p dangerouslySetInnerHTML={{ __html: property.about }}></p>
        </div>
      </div>
    </div>
  );
};

export default ShowPropertyPage;
