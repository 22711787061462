import React from 'react';
import {
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
  Button,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../components/ColorModeSwitcher';
import { Logo } from '../components/Logo';
import styles from '../styles/styles.css';
import { useState } from 'react';
import { saveUser } from '../utils/api';
import _ from 'lodash';
import { logErr, logInfo, redirect } from '../utils/uiutil';
import {
  Route,
  Routes,
  useNavigate,
  Link as RouteLink,
} from 'react-router-dom';
import { MyCaptcha } from '../components/MyCaptcha';
import AgreementsModal from '../components/AgreementsModal';

function SignupPage() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const onAgreementModalClosed = () => {
    setModalOpen(false);
  };

  const [data, setData] = useState({
    username: '',
    usernameErr: '',

    email: '',
    emailErr: '',

    firstName: '',
    firstNameErr: '',

    lastName: '',

    password: '',
    passwordErr: '',

    phone: '',
    phoneErr: '',

    captchaKey: '',
    captchaValue: '',
  });

  const doOpenAgreementModal = () => {
    setModalOpen(true);
  };

  const isInvalid = field => {
    return !_.isEmpty(data[`${field}Err`]);
  };

  const validateInputs = () => {
    const dataUpd = {};
    if (!data.username || data.username.length < 5) {
      dataUpd.usernameErr = 'Username is required, minimum 5 characters';
    }
    if (!data.email || data.email.length < 5 || data.email.length > 50) {
      dataUpd.emailErr = 'Email is required, between 5 to 50 characters';
    }
    if (!data.firstName || data.firstName.length > 30) {
      dataUpd.firstNameErr = 'First name is required, max 30 characters';
    }
    if (!data.password || data.password.length < 5) {
      dataUpd.passwordErr = 'Password is required, minimum 5 characters';
    }
    if (!data.phone) {
      dataUpd.phoneErr = 'Phone is required';
    }

    logInfo('Data', dataUpd);

    setData({ ...data, ...dataUpd });

    return _.isEmpty(dataUpd);
  };

  const clearErr = field => {
    setData({ ...data, [`${field}Err`]: '' });
  };

  const onChangeCaptcha = ({ key, value }) => {
    logInfo('captcha', key, value);
    setData({ ...data, captchaKey: key, captchaValue: value });
  };

  const doSignup = async () => {
    logInfo('Saving', data);
    if (validateInputs()) {
      const result = await saveUser(data);
      if (result?.data?.data?.user?.saveUser?.status == 'SUCCESS') {
        navigate('/status', {
          replace: true,
          state: { action: 'accountCreated' },
        });
      } else {
        logErr('Error creating user');
      }
    }
  };

  const doCancel = () => {
    return redirect('/signup');
  };

  const updateValue = name => event => {
    setData({ ...data, [name]: event.target.value, [`${name}Err`]: '' });
  };

  return (
    <Box
      margin="8px auto"
      maxW="sm"
      borderWidth="2px"
      borderRadius="lg"
      overflow="hidden"
    >
      <Grid minH="100vh" p={2}>
        <VStack spacing={4}>
          <Heading as="h2" size="md" noOfLines={1}>
            Create an account
          </Heading>
          <FormControl isInvalid={isInvalid('username')}>
            <FormLabel>Displayed username</FormLabel>
            <Input
              type="text"
              placeholder="username"
              value={data.username}
              onChange={updateValue('username')}
              maxLength={15}
              minLength={4}
            />
            <FormErrorMessage>{data.usernameErr}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={isInvalid('email')}>
            <FormLabel>Email address</FormLabel>
            <Input
              type="email"
              placeholder="me@email.com"
              value={data.email}
              onChange={updateValue('email')}
              maxLength={50}
              minLength={5}
            />
            <FormErrorMessage>{data.emailErr}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={isInvalid('password')}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              placeholder="..."
              value={data.password}
              onChange={updateValue('password')}
              maxLength={30}
              minLength={5}
            />
            <FormErrorMessage>{data.passwordErr}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={isInvalid('phone')}>
            <FormLabel>Mobile phone number</FormLabel>
            <Input
              type="phone"
              placeholder="4081235678"
              value={data.phone}
              onChange={updateValue('phone')}
            />
            <FormErrorMessage>{data.phoneErr}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={isInvalid('firstName')}>
            <FormLabel>First name</FormLabel>
            <Input
              type="text"
              placeholder="John"
              value={data.firstName}
              onChange={updateValue('firstName')}
              maxLength={30}
            />
            <FormErrorMessage>{data.firstNameErr}</FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel>Last name</FormLabel>
            <Input
              type="text"
              placeholder="Smith"
              value={data.lastName}
              onChange={updateValue('lastName')}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Captcha verification</FormLabel>
            <MyCaptcha onChange={onChangeCaptcha} />
          </FormControl>
          <Stack spacing={4} direction="row" align="center">
            <span style={{ paddingLeft: '3em', fontSize: '.75em' }}>
              By signing up for Rentzig I agree to&nbsp;
              <Link
                to="#"
                style={{ textDecoration: 'underline' }}
                onClick={doOpenAgreementModal}
              >
                the Terms and Conditions.
              </Link>
            </span>
          </Stack>
          <Stack spacing={4} direction="row" align="center">
            <Button colorScheme="blue" size="md" onClick={doSignup}>
              Sign up
            </Button>
            <Button colorScheme="gray" size="md" onClick={doCancel}>
              Cancel
            </Button>
          </Stack>
        </VStack>
      </Grid>
      <AgreementsModal open={modalOpen} closed={onAgreementModalClosed} />
    </Box>
  );
}

export default SignupPage;
