import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Spinner,
  Center,
  Icon,
  Button,
  Stack,
} from '@chakra-ui/react';
import { useSearchParams, Link as GotoLink } from 'react-router-dom';
import { confirmEmail } from '../utils/userutil';
import { FaCheck, FaExclamationCircle } from 'react-icons/fa';
import { logInfo } from '../utils/uiutil';

function ConfirmEmailPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [confirmed, setConfirmed] = useState('');

  useEffect(() => {
    const _confirmEmail = async () => {
      // Confirm email task. Callback is optional.
      logInfo('confirm email', token);
      const result = await confirmEmail(token);
      logInfo('confirmed', result);
      if (result?.data?.data?.auth?.verifyEmail?.status == 'SUCCESS') {
        setConfirmed('CONFIRMED');
      } else {
        setConfirmed('ERROR');
      }
    };

    _confirmEmail();

    return () => {
      logInfo('cleanup...');
    };
  }, []);

  return (
    <>
      <Center height="80vh">
        <Box display="flex" flexDirection="row">
          {confirmed === 'CONFIRMED' && (
            <>
              <Stack direction="column" spacing={4} textAlign="center">
                <Stack direction="row" spacing={4}>
                  <Icon as={FaCheck} boxSize={6} />
                  <Text marginLeft="20px">Your email is confirmed...</Text>
                </Stack>
                <Link as={GotoLink} to="/">
                  <Button colorScheme="teal" variant="solid">
                    Back to Home
                  </Button>
                </Link>
              </Stack>
            </>
          )}
          {confirmed === '' && (
            <>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
              <Text fontSize="2xl" fontWeight="bold" as="h1" marginLeft="20px">
                Confirming Email...
              </Text>
            </>
          )}
          {confirmed === 'ERROR' && (
            <>
              <Stack direction="column" spacing={4} textAlign="center">
                <Stack direction="row" spacing={4}>
                  <Icon as={FaExclamationCircle} boxSize={6} />
                  <Text marginLeft="20px">Your link may have expired...</Text>
                </Stack>
                <Link as={GotoLink} to="/">
                  <Button colorScheme="teal" variant="solid">
                    Back to Home
                  </Button>
                </Link>
              </Stack>
            </>
          )}
        </Box>
      </Center>
    </>
  );
}

export default ConfirmEmailPage;
