import { Box, Center, Heading, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const SearchLinksPage = () => {
  return (
    <div>
      <Heading as="h1" mb={16} textAlign="center">
        Rental Properties
      </Heading>
      <VStack>
        <Link to="/findProperties?loc=San%20Francisco,%20CA&dist=60">
          Rental properties near San Francisco
        </Link>
        <Link to="/findProperties?loc=Los%20Angeles,%20CA&dist=60">
          Rental properties near Los Angeles
        </Link>
        <Link to="/findProperties?loc=Houston,%20TX&dist=50">
          Rental properties near Houston
        </Link>
        <Link to="/findProperties?loc=Miami,%20FL&dist=50">
          Rental properties near Miami
        </Link>
        <Link to="/findProperties?loc=Seattle,%20WA&dist=50">
          Rental properties near Seattle
        </Link>
      </VStack>
    </div>
  );
};

export default SearchLinksPage;
