import React from 'react';
import _ from 'lodash';
import {
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
  Button,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../components/ColorModeSwitcher';
import { Logo } from '../components/Logo';
import styles from '../styles/styles.css';
import { useState } from 'react';
import { loginUser, saveUser } from '../utils/api';
import {
  Route,
  Routes,
  useNavigate,
  Link as RouteLink,
} from 'react-router-dom';
import { logoutUser } from '../utils/authutil';
import { useUserStore } from '../stores/userStore';
import { logInfo } from '../utils/uiutil';

function LogoutPage() {
  const navigate = useNavigate();
  const { removeUser } = useUserStore();
  const doLogout = async () => {
    const response = await logoutUser();

    logInfo('logout', response);

    if (response?.data?.data?.auth?.logout?.status === 'SUCCESS') {
      logInfo('Logout success');
      removeUser();
      return navigate('/', {
        replace: true,
      });
    } else {
      logInfo('Logout failed');
    }
  };

  return (
    <>
      <Box
        w="md"
        margin="8px auto"
        maxW="sm"
        borderWidth="2px"
        borderRadius="lg"
        overflow="hidden"
      >
        <Grid p={2}>
          <VStack spacing={4}>
            <Heading as="h2" size="md" noOfLines={1}>
              Logout
            </Heading>
            <Text>Please confirm here to logout.</Text>
            <Stack spacing={4} direction="row" align="center">
              <Button colorScheme="blue" size="md" onClick={doLogout}>
                Logout
              </Button>
              <Button colorScheme="gray" size="md">
                Back
              </Button>
            </Stack>
          </VStack>
        </Grid>
      </Box>
    </>
  );
}

export default LogoutPage;
